import { Injectable } from '@angular/core';
import { AppConfig } from '../app-config';
import { HTTP } from '@ionic-native/http/ngx';

@Injectable({
  providedIn: 'root'
})
export class WordpressService {
  cachedData: any;

  constructor(
    
    public appConfig: AppConfig,
    public nativeHttp: HTTP
  ) { }

  getColors(params) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/wp/v2/color?page="+params.page+"&per_page="+params.per_page;
    //url = this.initUrl(url, '');
    return new Promise(function (resolve, reject) {
    fetch(url)
      .then(data=> {
        if (data) {
          service.cachedData = data.json();
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      })
      .catch(e => {
        alert(JSON.stringify(e));
      });
    });
  }

  getFenster() {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/wp/v2/fenster?page=1&per_page=100";
    //url = this.initUrl(url, '');
    return new Promise(function (resolve, reject) {
    fetch(url)
      .then(data => {
        if (data) {
          service.cachedData = data.json();
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      })
      .catch(e => {
        alert(JSON.stringify(e));
      });
    });
  }

  getTueren() {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/wp/v2/tuer?page=1&per_page=100";
    //url = this.initUrl(url, '');
    return new Promise(function (resolve, reject) {
    fetch(url)
      .then(data => {
        if (data) {
          service.cachedData = data.json();
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      })
      .catch(e => {
        alert(JSON.stringify(e));
      });
    });
  }

  getTerrassen() {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/wp/v2/terrassensysteme?page=1&per_page=100";
    //url = this.initUrl(url, '');
    return new Promise(function (resolve, reject) {
    fetch(url)
      .then(data => {
        if (data) {
          service.cachedData = data.json();
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      })
      .catch(e => {
        alert(JSON.stringify(e));
      });
    });
  }

  getZubehoer(params) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/wp/v2/zubehoer?page="+params.page+"&per_page="+params.per_page;
    //url = this.initUrl(url, '');
    return new Promise(function (resolve, reject) {
    fetch(url)
      .then(data => {
        if (data) {
          service.cachedData = data.json();
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      })
      .catch(e => {
        alert(JSON.stringify(e));
      });
    });
  }

  getAllCategories() {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/wp/v2/categories?page=1&per_page=100";
    //url = this.initUrl(url, '');
    return new Promise(function (resolve, reject) {
    fetch(url)
      .then(data => {
        if (data) {
          service.cachedData = data.json();
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      })
      .catch(e => {
        alert(JSON.stringify(e));
      });
    });
  }

  getFensterProduct(id) {
    var service = this;
    let url = service.appConfig.Shop_URL + "/wp-json/wp/v2/fenster/"+id;
    //url = this.initUrl(url, '');
    return new Promise(function (resolve, reject) {
    fetch(url)
      .then(data => {
        if (data) {
          service.cachedData = data.json();
          resolve(service.cachedData);
        }
        else {
          reject();
        }
      })
      .catch(e => {
        alert(JSON.stringify(e));
      });
    });
  }

}
