import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class TbarService {
  hideBar: boolean = false;
  cartBage:number=0;
    constructor(
      public storage: Storage,
    ) {

    }

    updateCart(){
      this.storage.get('productCart').then(async(data) => {
        if (data){
        this.cartBage = data.length;
        //console.log(this.tbarService.cartBage);
        } else {
          this.cartBage = 0;
        } 
      });
    }
}
