/*
  Generated class for the ShopConstant provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/
export class AppConfig {
  Shop_Signature_Method = 'HMAC-SHA1';
  Shop_Nonce_Length = 32;
  Shop_Parameter_Seperator = ', ';
  Shop_Language = 'de';

  Shop_Name = "Fenster & Türen";
  Shop_Version = "1.0.0";

  Shop_URL = "https://arenafenster.ch/new";
  Shop_ConsumerKey = "ck_ffe4edb5c5213a29ef5e0a117d321bde4fa3de69";
  Shop_ConsumerSecret = "cs_5debab7b0a5cb345812504e19f96d5d489cdc0f4";
  App_Secret = "ad3b5fe554c417d97efd631f0d4c9fae83268efa12cc6b5f46bd273c513d9d4a";

  Is_Vendor_Pro = true;

  OneSignal_UserId = '';
  OneSignal_PushToken = '';



  Show_Home_Slide = true;//whether show home slide

  Shop_Currency = "";//no need setting,will get from your Website
  Shop_Currency_format = "";//no need setting,will get from your Website

  Shop_Shipping = [
    //{ id: 'flat_rate:1', name: 'Flat Rate', cost: 5 },
    //{ id: 'flat_rate:2', name: 'Worldwide Flat Rate', cost: 15 }
  ];

  //Stripe payment setting
  Enable_Stripe = true;//Enable or disable stripe
  Stripe_Live_Secret_Key = "sk_live_4DAw17c7Muy7ljrEX0VReYIw";//Live mode Secret Key
  Stripe_Live_Publishable_Key = "pk_live_56QSypEUyvNx6H24hxQqjCSN";//Live mode Publishable Key

  Enable_Stripe_Test_Mode = false;//Enable or disable test mode
  Stripe_Test_Secret_Key = "sk_test_1YcSznQpoERCk1JcSlcfp6hq";//Test mode Secret Key
  Stripe_Test_Publishable_Key = "pk_test_EWekCIPfeLDZkcacXc5vNfGq";//Test mode Publishable Key

  //Paypal setting
  //PayPalEnvironmentNoNetwork, PayPalEnvironmentSandbox, PayPalEnvironmentProduction
  //if you want to test payment,set Environments to PayPalEnvironmentSandbox,otherwise set to PayPalEnvironmentProduction
  Paypal_Environments = "PayPalEnvironmentProduction";
  PayPal_EnvironmentProduction = "Afa1aBdPQFdJbr7SOqrbvEppXxFhIqNkMgOKgAkExDD77qesy1DwNsI47jSa4zGTNVfPK3LoBHnlt2fx";
  PayPal_EnvironmentSandbox = "";

  //Onesignal setting
  //Please check our online document set these
  Onesignal_Enable = false;//enable/disable Onesignal
  OneSignal_AppID = "9ab5ba50-1067-4edd-b781-5cfbd5ebafe8";
  GCM_SenderID = "631766550027";

  constructor() {
  }
}
