import { Component, OnInit } from '@angular/core';
import { WordpressService } from './providers/wordpress.service';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { TbarService } from './providers/tbar.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public appPages = [
    { title: 'Home', url: 'home', icon: 'mail' },
    { title: 'Calculator', url: 'calculator', icon: 'paper-plane' },
    { title: 'Product', url: 'product', icon: 'heart' },
    { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
    { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor(
    public woService: WordpressService,
    public router: Router,
    public storage: Storage,
    public tbarService: TbarService,
  ) {

  }


  ngOnInit() {
    this.storage.create();
    this.storage.get('productCart').then(async(data) => {
      if (data){
      this.tbarService.cartBage = data.length;
      //console.log(this.tbarService.cartBage);
      } else {
        this.tbarService.cartBage = 0;
      } 
    });
  }

  goProduct() {
    this.router.navigate(['products']);
  };

  goCalculator() {
    this.router.navigate(['calculator']);
  };

  goHome() {
    this.router.navigate(['home']);
  };

  goCart() {
    this.router.navigate(['cart']);
  };
}
